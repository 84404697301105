/*
 * @Author: fan.lion@foxmail.com
 * @Date: 2019-09-16 19:37:46
 * @Last Modified by: fan.lion@foxmail.com
 * @Last Modified time: 2019-09-25 22:02:52
 *
 * 首页
 */
import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classnames from "classnames"
import { useIntl, Link } from "gatsby-plugin-intl"
import Slider from "react-slick"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { useSiteMetadata, useStaticQueryIndexImages } from "../hooks"
import style from "../styles/index.module.css"

import section2Icon1 from "../images/home_section2_icon1.png"
import section2Icon2 from "../images/home_section2_icon2.png"
import section2Icon3 from "../images/home_section2_icon3.png"
import section2Icon4 from "../images/home_section2_icon4.png"
import section2Icon5 from "../images/home_section2_icon5.png"

import section3Icon1 from "../images/p1icon1-1@2x.png"
import section3Icon2 from "../images/p2icon-1@2x.png"
import section3Icon3 from "../images/p3icon-1@2x.png"
import section3Icon4 from "../images/p4icon-1@2x.png"
import section3Icon5 from "../images/p5icon-2@2x.png"

const IndexPage = () => {
  const sliderRef = useRef(null)
  const intl = useIntl()
  const site = useSiteMetadata()
  const imgData = useStaticQueryIndexImages()

  const [sliderIndex, setSliderIndex] = useState(0)
  const [popAnimates, setPopAnimates] = useState({
    animate11: "",
    animate12: "",
    animate21: "",
    animate22: "",
    animate31: "",
    animate41: "",
    animate51: "",
  })

  // 跳转到指定到Slider页
  const handleSlickGoTo = index => {
    sliderRef.current.slickGoTo(index)
  }

  const beforeSliderChange = (current, next) => {
    switch (next) {
      case 0: {
        setPopAnimates(
          Object.assign(popAnimates, {
            animate11: "animated bounceInLeft",
            animate12: "animated bounceInLeft",
          })
        )
        break
      }
      case 1: {
        setPopAnimates(
          Object.assign(popAnimates, {
            animate21: "animated bounceInRight",
            animate22: "animated bounceInRight",
          })
        )
        break
      }
      case 2: {
        setPopAnimates(
          Object.assign(popAnimates, {
            animate31: "animated bounceInUp",
          })
        )
        break
      }
      case 3: {
        setPopAnimates(
          Object.assign(popAnimates, {
            animate41: "animated bounceInUp",
          })
        )
        break
      }
      case 4: {
        setPopAnimates(
          Object.assign(popAnimates, {
            animate51: "animated bounceInUp",
          })
        )
        break
      }
    }
    setSliderIndex(next)
  }

  const afterSliderChange = current => {
    setPopAnimates({
      animate11: "",
      animate12: "",
      animate21: "",
      animate22: "",
      animate31: "",
      animate41: "",
      animate51: "",
    })
  }

  const section4Pop11 = `/images/${intl.locale}_p1-1@2x.png`
  const section4Pop12 = `/images/${intl.locale}_p1-2@2x.png`
  const section4Pop21 = `/images/${intl.locale}_p2-1@2x.png`
  const section4Pop22 = `/images/${intl.locale}_p2-2@2x.png`
  const section4Pop31 = `/images/${intl.locale}_p3-1@2x.png`
  const section4Pop41 = `/images/${intl.locale}_p4-1@2x.png`
  const section4Pop51 = `/images/${intl.locale}_p5-1@2x.png`
  const supportTrail = site.support_trail === "true" // 是否支持试用
  const supportConsole = site.support_console === "true" // 是否支持控制台

  return (
    <Layout activeNav="service">
      <SEO
        title={intl.formatMessage({ id: '首页' })}
        lang={intl.locale}
      />
      <div className={style.main}>
        <section className={style.section1}>
          <div className={style.section1_left}>
            <h1>
              {site.name}
              {intl.formatMessage({ id: "智能视频协作平台" })}
            </h1>
            <p>
              {intl.formatMessage({ id: "高效、便捷、低成本的企业会议协作软件" })}
            </p>
            <div className={style.section1_btns}>
              {supportTrail && (
                <a
                  href="https://console.teampro.work/#/register"
                  target="_blank"
                  role="button"
                  className={classnames(style.link_btn, style.free_trail_btn)}
                >
                  {intl.formatMessage({ id: "免费试用" })}
                </a>
              )}
              <Link
                to="/download"
                role="button"
                className={classnames(style.link_btn, style.download_btn)}
              >
                {intl.formatMessage({ id: "立即下载" })}
              </Link>
            </div>
          </div>

          <div className={style.section1_right}>
            <Img fixed={imgData.section1Png.childImageSharp.fixed} alt="" />
          </div>
        </section>

        <section className={style.section2}>
          <h1 className={style.section2_row1}>
            {site.name}
            {intl.formatMessage({ id: "视频会议协同的最佳选择" })}
            <br />
            {intl.formatMessage({ id: "简单易用效果好" })}
          </h1>

          <div className={style.section2_row2}>
            <div className={style.section2_icon}>
              <img
                src={section2Icon1}
                alt={intl.formatMessage({ id: "高清视频会议" })}
              />
              <div>{intl.formatMessage({ id: "高清晰" })}</div>
            </div>

            <div className={style.section2_icon}>
              <img
                src={section2Icon2}
                alt={intl.formatMessage({ id: "企业直播" })}
              />
              <div>{intl.formatMessage({ id: "低延迟" })}</div>
            </div>

            <div className={style.section2_icon}>
              <img
                src={section2Icon3}
                alt={intl.formatMessage({ id: "智能协作工具" })}
              />
              <div>{intl.formatMessage({ id: "操作简单" })}</div>
            </div>

            <div className={style.section2_icon}>
              <img
                src={section2Icon4}
                alt={intl.formatMessage({ id: "H.324/SIP支持" })}
              />
              <div>{intl.formatMessage({ id: "全平台" })}</div>
            </div>

            <div className={style.section2_icon}>
              <img
                src={section2Icon5}
                alt={intl.formatMessage({ id: "会议统计" })}
              />
              <div>{intl.formatMessage({ id: "轻部署" })}</div>
            </div>
            <div className={style.section2_icon}></div>
          </div>
        </section>

        <section className={style.section3}>
          <h1 className={style.section3_row1}>
            {site.name}
            {intl.formatMessage({ id: "帮助企业提升85%会议效率" })}
            <br />
            {intl.formatMessage({ id: "丰富的会议协作功能" })}
          </h1>

          <div className={style.section3_row2}>
            <div className={style.section3_row2_left}>
              <Slider
                infinite
                autoplay
                dots={false}
                arrows={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                customPaging={0}
                autoplaySpeed={3000}
                pauseOnHover={false}
                ref={sliderRef}
                beforeChange={beforeSliderChange}
                afterChange={afterSliderChange}
              >
                <div className={style.section3_slider_item}>
                  <Img fluid={imgData.section3Png1.childImageSharp.fluid} alt="" />
                  <img
                    src={section4Pop11}
                    className={classnames(style.pop11, popAnimates.animate11)}
                    alt={intl.formatMessage({ id: "主屏展示当前主持人的高清视频画面" })}
                  />
                  <img
                    src={section4Pop12}
                    className={classnames(style.pop12, popAnimates.animate12)}
                    alt={intl.formatMessage({ id: "多分屏展示参会者画面支持不同的布局切换" })}
                  />
                </div>

                <div className={style.section3_slider_item}>
                  <Img fluid={imgData.section3Png2.childImageSharp.fluid} alt="" />
                  <img
                    src={section4Pop21}
                    className={classnames(style.pop21, popAnimates.animate21)}
                    alt={intl.formatMessage({ id: "快速创建您的会议日程" })}
                  />
                  <img
                    src={section4Pop22}
                    className={classnames(style.pop22, popAnimates.animate22)}
                    alt={intl.formatMessage({ id: "查看当前会议日程，可选择接收会议召开提醒" })}
                  />
                </div>

                <div className={style.section3_slider_item}>
                  <Img fluid={imgData.section3Png3.childImageSharp.fluid } alt="" />
                  <img
                    src={section4Pop31}
                    className={classnames(style.pop31, popAnimates.animate31)}
                    alt={intl.formatMessage({ id: "可使用互动白板、屏幕共享、文档共享功能，进行便捷有效的沟通" })}
                  />
                </div>

                <div className={style.section3_slider_item}>
                  <Img fluid={imgData.section3Png4.childImageSharp.fluid} alt="" />
                  <img
                    src={section4Pop41}
                    className={classnames(style.pop41, popAnimates.animate41)}
                    alt={intl.formatMessage({ id: "随时查看会议录像和报告" })}
                  />
                </div>

                <div className={style.section3_slider_item}>
                  <Img fluid={imgData.section3Png5.childImageSharp.fluid } alt="" />
                  <img
                    src={section4Pop51}
                    className={classnames(style.pop51, popAnimates.animate51)}
                    alt={intl.formatMessage({ id: "可以查看所有会议的召开和参与情况，以及详细的会议内操作记录" })}
                  />
                </div>
              </Slider>
            </div>

            <div className={style.section3_row2_right}>
              <div
                className={classnames({
                  [style.section3_row2_right_item]: true,
                  [style.section3_slider_active]: sliderIndex === 0,
                })}
                onClick={() => handleSlickGoTo(0)}
              >
                <img
                  src={section3Icon1}
                  alt={intl.formatMessage({ id: "互动白板" })}
                />
                <span>
                  {intl.formatMessage({ id: "互动白板：互动白板通过文档转码、轨迹实时同步，实现多人共享操作，进行实时互动" })}
                </span>
              </div>

              <div
                className={classnames({
                  [style.section3_row2_right_item]: true,
                  [style.section3_slider_active]: sliderIndex === 1,
                })}
                onClick={() => handleSlickGoTo(1)}
              >
                <img
                  src={section3Icon2}
                  alt={intl.formatMessage({ id: "会议室聊天" })}
                />
                <span>
                  {intl.formatMessage({
                    id:
                      "会议室聊天：同一会议活动内，可接入不同地域的几个会议人共同分享内容，会议人一键无缝切换，其他与会人无需退出另外再登录",
                  })}
                </span>
              </div>

              <div
                className={classnames({
                  [style.section3_row2_right_item]: true,
                  [style.section3_slider_active]: sliderIndex === 2,
                })}
                onClick={() => handleSlickGoTo(2)}
              >
                <img
                  src={section3Icon3}
                  alt={intl.formatMessage({ id: "桌面共享" })}
                />
                <span>
                  {intl.formatMessage({
                    id:
                      "桌面共享：可在线共享文档，共享多媒体，共享屏幕，共享白板等，视频、音频、图片、文档等同步呈现在眼前",
                  })}
                </span>
              </div>

              <div
                className={classnames({
                  [style.section3_row2_right_item]: true,
                  [style.section3_slider_active]: sliderIndex === 3,
                })}
                onClick={() => handleSlickGoTo(3)}
              >
                <img
                  src={section3Icon4}
                  alt={intl.formatMessage({ id: "会议录像和回看" })}
                />
                <span>
                  {intl.formatMessage({ id: '会议录像和回看：' })}
                  {site.name}
                  {intl.formatMessage({ id: '可以对会议进行录像并保存，便于后续查找，多用于培训等场合' })}
                </span>
              </div>

              <div
                className={classnames({
                  [style.section3_row2_right_item]: true,
                  [style.section3_slider_active]: sliderIndex === 4,
                })}
                onClick={() => handleSlickGoTo(4)}
              >
                <img
                  src={section3Icon5}
                  alt={intl.formatMessage({ id: "美颜" })}
                />
                <span>
                  {intl.formatMessage({
                    id:
                      "美颜：视频开会时增加美颜功能，让您随时随地美美上镜，在客户的面前时刻保持最佳形象",
                  })}
                </span>
              </div>
            </div>
          </div>

          {supportConsole && (
            <div className={style.section3_row3}>
              <a
                role="button"
                href="https://console.teampro.work/#/register"
                target="_blank"
                className={style.section3_row3_btn}
              >
                {intl.formatMessage({ id: "立即体验" })}
              </a>
            </div>
          )}
        </section>

        <section className={style.section4}>
          <h1 className={style.section4_row1}>
            {site.name}
            {intl.formatMessage({ id: "能帮到您什么" })}
            <br />
            {intl.formatMessage({ id: "降低成本、轻松开会、赢得先机" })}
          </h1>

          <div className={style.section4_row2}>
            <div className={style.section4_item}>
              <Img
                fixed={imgData.section4Png1.childImageSharp.fixed}
                alt={intl.formatMessage({ id: "降低各种成本" })}
              />
              <div className={style.section4_item_foot}>
                <h2>{intl.formatMessage({ id: "高效" })}</h2>
                <p>
                  {intl.formatMessage({
                    id:
                      "支持高质量视频会议、快速的商务即时沟通、大规模企业直播，满足所有协作沟通场景",
                  })}
                </p>
              </div>
            </div>

            <div className={style.section4_item}>
              <Img
                fixed={imgData.section4Png2.childImageSharp.fixed}
                alt={intl.formatMessage({ id: "保障及时决策" })}
              />
              <div className={style.section4_item_foot}>
                <h2>{intl.formatMessage({ id: "易用" })}</h2>
                <p>
                  {intl.formatMessage({
                    id:
                      "简单清晰的操作流程，便捷的会议管理，快速流畅的会议和协作体验",
                  })}
                </p>
              </div>
            </div>

            <div className={style.section4_item}>
              <Img
                fixed={imgData.section4Png3.childImageSharp.fixed}
                alt={intl.formatMessage({ id: "更加灵活便捷" })}
              />
              <div className={style.section4_item_foot}>
                <h2>{intl.formatMessage({ id: "智能" })}</h2>
                <p>
                  {intl.formatMessage({
                    id:
                      "支持智能会议纪要，支持AR/VR等新一代可穿戴设备，提供AI能力接入支持",
                  })}
                </p>
              </div>
            </div>

            <div className={style.section4_item}>
              <Img
                fixed={imgData.section4Png4.childImageSharp.fixed}
                alt={intl.formatMessage({ id: "安全稳定更强" })}
              />
              <div className={style.section4_item_foot}>
                <h2>{intl.formatMessage({ id: "开放" })}</h2>
                <p>
                  {intl.formatMessage({
                    id:
                      "支持H.323/SIP协议，可在各类型会议硬件上稳定运行。支持第三方服务的集成",
                  })}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* 背景三角形 */}
        <div className={style.triangle1}></div>
        <div className={style.triangle2}></div>
        <div className={style.triangle3}></div>
        <div className={style.triangle4}></div>
      </div>
    </Layout>
  )
}

export default IndexPage
